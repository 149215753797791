/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, Link } from 'gatsby';
import { Themed } from 'theme-ui';
import { useLangKey } from '../../utils/lang';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import SanityContent from '../content/sanity-content.js';
import More from '../more';
import { MoreHoverContextProvider, useSetHover } from '../MoreHoverContext';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

const gen_excerpt = (raw) => {
	const excerptLength = 400;

	let pointer = 0;

	const shorten_children = (children) => {
		let ret = [];
		for (const child of children) {
			const { text } = child;
			//pointer += text.length;
			if (pointer + text.length <= excerptLength) {
				ret.push(child);
				pointer += text.length;
			} else {
				let str = child.text.substring(0, excerptLength - pointer);
				const lastIndex = str.lastIndexOf(' ');
				str = str.substring(0, lastIndex);
				ret.push({ ...child, marks: [ ...child.marks, 'last' ], text: str });
				pointer = excerptLength;
				break;
			}
		}
		return ret;
	};

	let arr = [];
	for (const value of raw) {
		//const children = value.children.
		arr.push({ ...value, children: shorten_children(value.children) });
		if (pointer >= excerptLength) break;
	}

	// arr.push({
	// 	_key: 'baldd',
	// 	_type: 'block',
	// 	children: [
	// 		{
	// 			_key: 'balafef',
	// 			_type: 'span',
	// 			marks: ['last'],
	// 			text: ' ...'
	// 		}
	// 	]
	// });

	return arr;
};

const StueckePage = ({ location, pageContext, data }) => {
	const nodes = data.allSanityStueckePage.nodes[0].members;
	const title = data.allSanityStueckePage.nodes[0].title.note;
	//const handleClick = useLetBlink()
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			<div sx={{ width: '100%', /* borderTop: 'thin solid #d1d1d1',*/ textAlign: 'center' }}>
				<div sx={{ width: '100%', maxWidth: 820, mx: 'auto' }}>
					{/* <GatsbyImage objectFit='contain' sx={{ mt: 5, mb: 4 }} image={flower} />
					<Themed.h1 sx={{ mb: 5, fontSize: 6, color: '#ff2fac' }}>{title}</Themed.h1> */}

					<div sx={{ position: 'relative', textAlign: 'center', mt: 5 }}>
						<StaticImage sx={{ width: '320px', filter: "hue-rotate(113deg) saturate(0.5)", maxWidth:'90vw' }} placeholder='none' src="./ordaniteur_Blume.png" />

						<div
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								width: '100%',
								fontFamily: 'Oswald',
								fontStyle: 'normal',
								fontWeight: 'bold',
								fontSize: 54,
								lineHeight: '100%',
								transform: 'translate(-50%, -50%)',
								fontSize: [6,null,7],
								textShadow: '4px 5px 0px #ff2fac',
								color: 'white',
								letterSpacing: '0.07em',
								backgroundColor: '#ffffff3d',
								/* padding:20, */
								paddingBottom: 30,
								paddingTop: 20
							}}
						>
							{title}
						</div>
					</div>
					{/* <svg width="800" height="2" viewBox="0 0 640 2" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="1" x2="640" y2="1" stroke="black" stroke-width="2" />
					</svg> */}

					<PlaysGrid nodes={nodes} />
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query PlaysQuery($langCode: String = "de") {
		allSanityStueckePage {
			nodes {
				members {
					playIntl {
						name(langCode: $langCode)
						slug(langCode: $langCode)
					}
				    image {
						asset {
							gatsbyImageData
						}
					}
				}
				title {
					note(langCode: $langCode)
				}
			}
		}
	}
`;

export default StueckePage;
const Content = ({ play }) => {
	const setHover = useSetHover();
	return (
		<div
			sx={{ cursor: 'pointer', gridColumn: 2, gridRow: '2' }}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<SanityContent data={play.playIntl.rawDsc && gen_excerpt(play.playIntl.rawDsc)} />
		</div>
	);
};
function PlaysGrid({ nodes }) {
	const langKey = useLangKey();
	return (
		<div sx={{ display: 'grid', gridTemplateColumns: ['1fr',null,'1fr 1fr'], mt: '100px' }}>
			{nodes.map((play) => (
				<MoreHoverContextProvider>
					<div>
						<Link sx={{ textDecoration: 'none' }} to={`/${langKey}/${play.playIntl.slug}`}>
							{play.image && (
								<GatsbyImage
									sx={{ width: 304 * 1.3, maxWidth:'90vw' }}
									image={getImage(play.image.asset)}
									objectFit="contain"
								/>
							)}
							<Themed.h2 sx={{ mt: 3, mb: 5, px: 4, color: '#ff2fac', fontSize: 3, textShadow: 'none', textTransform: 'none'}}>
								{play.playIntl.name}
							</Themed.h2>
							{/* <Content play={play} /> */}
							{/* <More></More> */}

							{/* <div sx={{display:'inline'}}>...</div> */}
						</Link>
					</div>
				</MoreHoverContextProvider>
			))}
		</div>
	);
}
